import debounce from '../utilities/debouncer';
import { getBreakpoint } from '../utilities/breakpoints';

export default class {
    constructor({
        id,
        slideHandle,
        prevHandle,
        nextHandle,
        progressHandle,
        startClass,
        endClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const slides = el.querySelectorAll(slideHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const progress = el.querySelector(progressHandle);

        // TODO this doesn't account for multiple sliders on the page
        const listSlideTriggers = Array.from(document.querySelectorAll('[data-list-slide-index]'))

        // State variables
        let activeIndex = 0;
        let offset = 0;
        let slidesToScroll = getBreakpoint() === 'sm' ? 1 : 1;

        // Helper functions
        function moveSlides() {
            console.log('hello')
            offset = -1 * slides[activeIndex].offsetLeft;

            if (activeIndex === 0) {
                el.classList.add(startClass);
                prev.setAttribute('disabled', true);
            } else {
                el.classList.remove(endClass);
                prev.removeAttribute('disabled');
            }

            if (activeIndex === slides.length - 3) {
                el.classList.add(endClass);
                next.setAttribute('disabled', true);
            } else {
                el.classList.remove(startClass);
                next.removeAttribute('disabled');
            }

            Array.from(slides).forEach((slide, i) => {
                slide.style.transform = `translateX(${offset}px)`;

                if (i === activeIndex) {
                    slide.classList.add('is-active')
                    return;
                }

                slide.classList.remove('is-active')
            });

            // Add 1 to activeIndex to make sure a little red always shows in progress bar
            const scale = Math.min(1, (activeIndex + 1) / (slides.length));

            progress.style.transform = `scaleX(${scale})`;
        }

        // Event handler functions
        const handleResize = debounce(() => {
            slidesToScroll = getBreakpoint() === 'sm' ? 1 : 1;

            moveSlides(offset);
        }, 500);
        function handlePrev() {
            if (activeIndex === 0) return;

            // Update state variables
            activeIndex = activeIndex - slidesToScroll < 0
                ? 0
                : activeIndex - slidesToScroll;

            moveSlides();
        }
        function handleNext() {
            if (activeIndex === slides.length - 1) return;

            // Update state variables
            activeIndex = activeIndex + slidesToScroll > slides.length - 1
                ? slides.length - 1
                : activeIndex + slidesToScroll;

            moveSlides();
        }

        function handleListSlideTriggerClick(event) {
            const target = event.target.matches('[data-list-slide-index]') && event.target || event.target.closest('[data-list-slide-index]')

            activeIndex = parseInt(target.dataset.listSlideIndex)
            console.log(activeIndex)
            moveSlides()
        }

        let startX;
        let endX;

        function handleSlideTouchStart(event) {
            startX = event.touches[0].clientX;
        }

        function handleSlideTouchMove(event) {
            endX = event.touches[0].clientX
        }

        function handleSlideTouchEnd(event) {
            if (startX > endX) {
                handleNext()
            } else if (startX < endX) {
                handlePrev()
            }
        }

        // Add event listeners
        window.addEventListener('resize', handleResize);
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);

        listSlideTriggers.forEach((button) => {
            button.addEventListener('click', handleListSlideTriggerClick)
        })

        slides.forEach((slide) => {
            slide.addEventListener('touchstart', handleSlideTouchStart)
            slide.addEventListener('touchmove', handleSlideTouchMove)
            slide.addEventListener('touchend', handleSlideTouchEnd)
        })

        // Initialize
        moveSlides();
    }
}
