import { events, emitEvent } from '../utilities/custom-events';
import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        imageLinkHandle,
        lightboxImageHandle,
        prevHandle,
        nextHandle,
        closeHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const imageLinks = el.querySelectorAll(imageLinkHandle);
        const images = Array.from(imageLinks).map(imageLink => imageLink.querySelector('img'));
        const lightboxImage = el.querySelector(lightboxImageHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const close = el.querySelector(closeHandle);

        // State variables
        let loadedCount = 0;
        let activeIndex = null;

        // Helper functions
        function setLightboxImage() {
            const src = images[activeIndex].getAttribute('data-src');

            lightboxImage.setAttribute('src', src);
        }

        // Event handler functions
        function handleLoad() {
            lightboxImage.style.width = '100%';
            lightboxImage.style.height = 'auto';

            if (lightboxImage.offsetHeight > lightboxImage.parentElement.offsetHeight) {
                lightboxImage.style.width = 'auto';
                lightboxImage.style.height = '100%';
            }
        }
        const handleOpens = Array.from(imageLinks).map((imageLink, i) => e => {
            e.preventDefault();

            activeIndex = i;

            setLightboxImage();
            emitEvent(events.lockScroll);
            el.classList.add(activeClass);
        });
        function handlePrev() {
            activeIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;

            setLightboxImage();
        }
        function handleNext() {
            activeIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
            console.log('hello')
            setLightboxImage();
        }
        function handleClose() {
            emitEvent(events.unlockScroll);
            el.classList.remove(activeClass);
        }
        function handleScroll() {
            Array.from(images).forEach(image => {
                if (
                    image.getAttribute('src')
                    || image.getBoundingClientRect().top > window.innerHeight * 1.5
                ) return;

                const src = image.getAttribute('data-src');

                loadedCount++;

                image.setAttribute('src', src);
            });

            if (loadedCount === images.length) {
                window.removeEventListener('scroll', handleScroll);
            }
        }
        const handleResize = debounce(() => {
            handleLoad();
        }, 500);

        // Add event listeners
        Array.from(imageLinks).forEach((imageLink, i) => {
            imageLink.addEventListener('click', handleOpens[i]);
        });
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
        lightboxImage.addEventListener('load', handleLoad);
        close.addEventListener('click', handleClose);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Initialize grid
        handleScroll();
    }
}
