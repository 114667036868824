export default class LazyLoadImages {
    constructor() {
        this.init();
        this.observeDOMChanges();
    }

    init() {
        this.lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-load-bg-img:not(.carousel-slider .lazy-load-bg-img)'));
        this.lazyCarouselSlides = [].slice.call(document.querySelectorAll('.carousel-slider .js_slide'));

        if ('IntersectionObserver' in window) {
            const lazyBackgroundObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const lazyBackground = entry.target;
                        lazyBackground.style.backgroundImage = `url(${lazyBackground.dataset.backgroundImage})`;
                        lazyBackgroundObserver.unobserve(lazyBackground);
                    }
                });
            });

            this.lazyBackgrounds.forEach(lazyBackground => {
                lazyBackgroundObserver.observe(lazyBackground);
            });

            const lazyCarouselObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const lazySlide = entry.target;
                        this.loadSlideImages(lazySlide);
                        lazyCarouselObserver.unobserve(lazySlide);
                    }
                });
            });

            this.lazyCarouselSlides.forEach(lazySlide => {
                lazyCarouselObserver.observe(lazySlide);
            });
        }

        // Add MutationObserver to watch for changes to the 'active-slide' class
        if ('MutationObserver' in window) {
            const carouselSlideObserver = new MutationObserver(mutationsList => {
                for (let i = 0; i < mutationsList.length; i++) {
                    const mutation = mutationsList[i];
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        const targetElement = mutation.target;
                        if (targetElement.classList.contains('active-slide')) {
                            // Preload the next slide
                            this.preloadNextSlide(targetElement);
                        }
                    }
                }
            });

            this.lazyCarouselSlides.forEach(lazySlide => {
                carouselSlideObserver.observe(lazySlide, { attributes: true });
            });
        }
    }

    observeDOMChanges() {
        if ('MutationObserver' in window) {
            const domObserver = new MutationObserver(mutationsList => {
                let addedNodes = [];
                mutationsList.forEach(mutation => {
                    addedNodes = addedNodes.concat(Array.from(mutation.addedNodes));
                });

                addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.matches('.lazy-load-bg-img:not(.carousel-slider .lazy-load-bg-img)')) {
                            this.lazyBackgrounds.push(node);
                            this.init();  // Re-initialize observers for new elements
                        }

                        if (node.matches('.carousel-slider .js_slide')) {
                            this.lazyCarouselSlides.push(node);
                            this.init();  // Re-initialize observers for new elements
                        }

                        // Check for lazy load elements inside the added node
                        node.querySelectorAll('.lazy-load-bg-img:not(.carousel-slider .lazy-load-bg-img)').forEach(el => {
                            this.lazyBackgrounds.push(el);
                            this.init();  // Re-initialize observers for new elements
                        });

                        node.querySelectorAll('.carousel-slider .js_slide').forEach(el => {
                            this.lazyCarouselSlides.push(el);
                            this.init();  // Re-initialize observers for new elements
                        });
                    }
                });
            });

            domObserver.observe(document.body, {
                childList: true,
                subtree: true
            });
        }
    }

    loadSlideImages(slide) {
        const lazyBackgrounds = slide.querySelectorAll('.lazy-load-bg-img');
        lazyBackgrounds.forEach(lazyBackground => {
            lazyBackground.style.backgroundImage = `url(${lazyBackground.dataset.backgroundImage})`;
        });
    }

    preloadNextSlide(currentSlide) {
        const nextSlide = currentSlide.nextElementSibling || this.lazyCarouselSlides[0];
        this.loadSlideImages(nextSlide);
    }
}
