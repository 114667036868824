import { events, emitEvent } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        hotspotHandle,
        buttonHandle,
        featureHandle,
        prevHandle,
        nextHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const hotspots = el.querySelectorAll(hotspotHandle);
        const buttons = el.querySelectorAll(buttonHandle);
        const features = el.querySelectorAll(featureHandle);

        // State variables
        let activeIndex = null;

        // Helper functions
        function setActiveIndex(i) {
            if (i === null) {
                el.classList.remove(activeClass);
            } else {
                el.classList.add(activeClass);
            }

            Array.from(hotspots).forEach((hotspot, ii) => {
                if (ii === i) {
                    hotspot.classList.add(activeClass);
                } else {
                    hotspot.classList.remove(activeClass);
                }
            });

            activeIndex = i;
        }

        // Event handler functions
        function handleCloseSidebar() {
            setActiveIndex(null);
        }
        const handleClicks = Array.from(hotspots).map((hotspot, i) => () => {
            if (activeIndex === i) {
                setActiveIndex(null);

                emitEvent(events.closeSidebar);

                return;
            }

            const markup = features[i].innerHTML;

            function cb() {
                setActiveIndex(i);

                function loadedCb(container) {
                    const prev = container.querySelector(prevHandle);
                    const next = container.querySelector(nextHandle);

                    function handlePrev() {
                        const prevIndex = activeIndex - 1 < 0
                            ? features.length - 1
                            : activeIndex - 1;

                        handleClicks[prevIndex]();
                    }
                    function handleNext() {
                        const nextIndex = activeIndex + 1 > features.length - 1
                            ? 0
                            : activeIndex + 1;

                        handleClicks[nextIndex]();
                    }

                    prev.addEventListener('click', handlePrev);
                    next.addEventListener('click', handleNext);
                }

                emitEvent(events.loadSidebar, { markup, cb: loadedCb });
            }

            if (el.classList.contains(activeClass)) {
                cb();
            } else {
                scrollTop(el, 0, cb);
            }
        });

        // Add event listeners
        window.addEventListener(events.closeSidebar, handleCloseSidebar);
        Array.from(hotspots).forEach((hotspot, i) => {
            console.log('hello')
            hotspot.addEventListener('click', handleClicks[i]);
        });
        Array.from(buttons).forEach((button, i) => {
            button.addEventListener('click', handleClicks[i]);
        });
    }
}
